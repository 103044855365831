/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_appsync_graphqlEndpoint:
    "https://6v47riq5z5dufc7uv7lskq2jzq.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-z4etftl3zfgjpnzl6qtnmprfze",
  aws_cognito_identity_pool_id:
    "us-west-2:a6e7d27d-b0c9-4a09-9bfd-6065c00d6d47",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_SzdbU86Rh",
  aws_user_pools_web_client_id: "6vnmtm3oh3r0acvvjrqgpq7ug8",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "BIRTHDATE", "NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
